


import React from "react";

// import a1 from '../../../assets/img/leatzmeat/about-chicken.png'
import { TiTick } from "react-icons/ti";
function AboutStory({ data,t }) {
  return (
    <>
      <div className="aboutSection p-30">
        <div className="container">

          <div className="row">
            <div className="col-lg-6">
              <div className="image">
                {/* <img src={a1} alt="" /> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="container">
                <h3 className="subtitle">{t('OUR STORY')}</h3>
                <p className="">
                 {data?.about_company}
                </p>
                <p>
                  {t('about-1')}
                </p>
              </div>
            </div>
          </div>
        
        </div>
      </div>
    </>
  );
}

export default AboutStory;
