import React from "react";
import bgImg from "../../../assets/img/get-app.png";
import sortImg from "../../../assets/img/sortImg.png";
import { Link } from "react-router-dom";
import { BsAndroid2 } from "react-icons/bs";
import { FaAppStoreIos } from "react-icons/fa";

const GetApp = () => {
  return (
    <>
      <section
        className="getApp"
        style={{
          backgroundImage: `url(${bgImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <div className="getAppText">
                <h3>Get the app & get Your  Groceries <br /> from home</h3>
                <div className="appImg">
                  <ul>
                    <li>
                      <Link to="/">
                        <div className="icon"><BsAndroid2 /></div>
                        <div className="text">
                          <span>Get it on</span>
                          <p>Google Play</p>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <div className="icon"><FaAppStoreIos />
                        </div>
                        <div className="text">
                          <span>Download on the</span>
                          <p>App Store</p>
                        </div>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="sortImg">
                <img src={sortImg} alt="sortImg" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GetApp;
