import React from "react";
import bgImg from "../../../assets/img/counter.PNG";

const SellCounter = () => {
  return (
    <>
      <section
        className="sellCounter"
        style={{
          backgroundImage: `url(${bgImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container">
          <div className="counterCard">
            <div className="title">
              <h6>Best Deals of the week!</h6>
              <h3>Grab the best Offer Of this Week!</h3>
            </div>
            <div className="body">
              <ul>
                <li>
                  <span>102</span>
                  Days
                </li>
                :
                <li>
                  <span>12</span>Hours
                </li>
                :
                <li>
                  <span>46</span>
                  Mins
                </li>
                :
                <li>
                  <span>6</span>
                  Secs
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SellCounter;
